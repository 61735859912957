import {
  ApiResponse,
  Locale,
  StatusStaticReference,
} from '@/shared/types/generic';
import { Partner, PartnerAdmin } from '@/shared/types/partners';
import { mockMetadata } from '@/mocks/generic/generic';
import site from '@/mocks/sites/mockSites';

const partnerStatus = [
  StatusStaticReference.ACTIVE,
  StatusStaticReference.DELETED,
];

const partnerAdminStatus = [...partnerStatus, StatusStaticReference.INACTIVE];

export const mockPartners: Partner[] = partnerStatus.map((status, index) => ({
  name: `Partner ${index + 1}`,
  adminsNumber: 1,
  clientsNumber: 1,
  createdBy: {
    email: 'admin@gmail.com',
    name: 'Admin',
  },
  lastUpdate: 1725447712585,
  status,
  language: Locale.US,
  reference: `partner-${index + 1}`,
  site,
}));

export const mockPartnerAdmins: PartnerAdmin[] = partnerAdminStatus.map(
  (status, index) => ({
    reference: `partner-admin-${index + 1}`,
    name: `Partner Admin ${index + 1}`,
    email: `partner-admin-${index + 1}@gmail.com`,
    status,
  }),
);

export const getPartners = (): ApiResponse<Partner[]> => ({
  metadata: mockMetadata(),
  results: mockPartners,
});

export const getPartnerAdmins = (): ApiResponse<PartnerAdmin[]> => ({
  metadata: mockMetadata(),
  results: mockPartnerAdmins,
});
