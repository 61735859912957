import { defineStore } from 'pinia';
import {
  EditOrganization,
  Feature,
  NewOrganizationRequest,
  Organization,
  SalesChannels,
} from '@/shared/types/organizations';
import organizationsService from '@/shared/services/organizationsService/organizationsService';
import featureService from '@/shared/services/featureService/featureService';
import { ApiResponse } from '@/shared/types/generic';
// import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
// import rolesService from '@/shared/services/rolesService/rolesService';
// import { GetRolesResponse } from '@/shared/types/roles';

export default defineStore({
  id: 'organizations',

  state: () => ({
    organizations: [] as Organization[],
    salesChannels: [] as SalesChannels[],
  }),

  getters: {
    mapperByReference: (state) => {
      return state.organizations.reduce((acc, organization) => {
        acc[organization.reference] = organization;
        return acc;
      }, {} as Record<string, Organization>);
    },
  },

  actions: {
    resetStore() {
      this.organizations = [];
    },

    async loadOrganizations() {
      try {
        this.organizations = (
          await organizationsService.getOrganizations()
        ).results.organizations;
      } catch (e) {
        this.organizations = [];
      }
    },

    createOrganization(newOrganization: NewOrganizationRequest) {
      return organizationsService.createOrganization(newOrganization);
    },

    async editOrganization(
      organizationReference: Organization['reference'],
      newOrganization: EditOrganization,
    ) {
      await organizationsService.editOrganization(
        organizationReference,
        newOrganization,
      );

      await this.loadOrganizations();
    },

    deleteOrganization(
      organizationReference: Organization['reference'],
    ): Promise<void> {
      return organizationsService.deleteOrganization(organizationReference);
    },

    async loadSalesChannels() {
      try {
        this.salesChannels = (
          await organizationsService.getSalesChannels()
        ).results.salesChannels;
      } catch (e) {
        this.salesChannels = [];
      }
    },

    async loadDetails(
      organizationReference: Organization['reference'],
    ): Promise<Organization> {
      const orgIndex = this.organizations.findIndex(
        (organization) => organization.reference === organizationReference,
      );

      const promises: Promise<ApiResponse<{ features: Feature[] }>>[] = [
        featureService.getFeaturesByOrganizationReference(
          this.organizations[orgIndex].reference,
        ),
        // rolesService.getRoleLevels(
        //   RoleTypeStaticReference.MANAGER,
        //   this.organizations[orgIndex].name,
        // ),
      ];

      const details = await Promise.all(promises);

      this.organizations[orgIndex] = {
        ...this.organizations[orgIndex],
        features: (details[0] as ApiResponse<{ features: Feature[] }>).results
          .features,
        // roles: details[1] as GetRolesResponse,
      };

      return this.organizations[orgIndex];
    },
  },
});
