import {
  ResponseMetadata,
  StatusStaticReference,
} from '@/shared/types/generic';
import { Admin } from '@/shared/types/admins';

export const createAdmin = (
  adminName: string,
  adminEmail: string,
  adminStatus: StatusStaticReference,
): Admin => {
  return {
    name: adminName,
    email: adminEmail,
    status: adminStatus,
  };
};

export const admins = [
  createAdmin('Admin1', 'admin1@gmail.com', StatusStaticReference.ACTIVE),
  createAdmin('Admin2', 'admin2@gmail.com', StatusStaticReference.INVITED),
  createAdmin('Admin3', 'admin3@gmail.com', StatusStaticReference.INACTIVE),
  createAdmin('Admin4', 'admin4@gmail.com', StatusStaticReference.DELETED),
];

export const metadata: ResponseMetadata = {
  totalItems: admins.length,
  lastUpdated: 1595030400000,
};

export const getAdmins = () => ({
  metadata,
  results: {
    admins,
  },
});
