<script setup lang="ts">
import IconHome from '@/assets/icons/icon-home.vue';
import IconUser from '@/assets/icons/icon-users.vue';
import IconTranslate from '@/assets/icons/icon-translate.vue';
import IconWrench from '@/assets/icons/icon-wrench-outline.vue';
import IconLeaderboard from '@/assets/icons/icon-leaderboard.vue';
import { DrawerLink } from '@/shared/types/components';
import ProfileDrawerLink from '@/components/profile/ProfileDrawerLink/ProfileDrawerLink.vue';
import AppDrawerLinkList from '../AppDrawerLinkList/AppDrawerLinkList.vue';
import AppVaibeLogo from '../AppVaibeLogo/AppVaibeLogo.vue';
import AppDrawer from '../AppDrawer/AppDrawer.vue';

interface Props {
  mini: boolean;
}

defineProps<Props>();

const model = defineModel<boolean>({ required: true });

const links: DrawerLink[] = [
  {
    to: '/organizations',
    icon: IconHome,
    label: 'nav.organizations',
  },
  // {
  //   to: '/partners',
  //   icon: IconLeaderboard,
  //   label: 'nav.partners',
  // },
  {
    to: '/admins',
    icon: IconUser,
    label: 'nav.admins',
  },
  {
    to: '/translations',
    icon: IconTranslate,
    label: 'nav.translations',
  },
  {
    to: '/challenge-scheduler',
    icon: IconWrench,
    label: 'nav.challengeScheduler',
  },
  {
    to: '/challenge-rewards',
    icon: IconLeaderboard,
    label: 'nav.challengeRewards',
  },
];
</script>

<template>
  <AppDrawer
    v-model="model"
    :mini="mini"
    :class="['app-layout-drawer', { 'app-layout-drawer--mini': mini }]"
  >
    <AppVaibeLogo class="app-layout-drawer__logo" :is-small="mini" />
    <div class="app-layout-drawer__content">
      <AppDrawerLinkList :links="links" :mini="mini" />
      <ProfileDrawerLink :mini="mini" />
    </div>
  </AppDrawer>
</template>

<style scoped lang="scss">
:deep(.app-layout-drawer) {
  display: flex;
  padding: 24px 32px;
  background: $gray-50;
  flex-direction: column;
}

:deep(.app-layout-drawer.app-layout-drawer--mini) {
  padding: 24px 0;
}

.app-layout-drawer__logo {
  height: 64px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  transition: all 0.3s;
  justify-content: flex-start;
}

.app-layout-drawer.app-layout-drawer--mini .app-layout-drawer__logo {
  margin-left: 16px;
}

.app-layout-drawer__content {
  flex-grow: 1;
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
