import { rest } from 'msw';
import basePath from '@/mocks/helpers/basePath';
import profile from '@/mocks/profile/mockProfile';
import { mockFeatureCodes, mockFeatures } from './features/mockFeatures';
import { getOrganizations } from './organizations/mockOrganizations';
import { getAdmins } from './admins/mockAdmins';
import { getPartnerAdmins, getPartners } from './partners/mockPartners';

const handlers = [
  rest.get(basePath('/profiles/admin'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(profile));
  }),

  rest.get(basePath('/feature'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFeatureCodes()));
  }),

  rest.get(basePath('/feature/{organizationName}'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFeatures()));
  }),

  rest.get(basePath('/feature/{organizationName}'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockFeatures()));
  }),

  rest.get(basePath('/organizations'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getOrganizations()));
  }),

  rest.delete(basePath('/organization/:organizationId'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath('/users/admin'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getAdmins()));
  }),

  rest.get(basePath('/partners'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPartners()));
  }),

  rest.get(basePath('/partners/admins/:reference'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPartnerAdmins()));
  }),

  rest.post(basePath('/partners'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.patch(basePath('/partners/:reference'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.delete(basePath('/partners/:reference'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export default handlers;
