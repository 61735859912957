import { AdminProfile } from '@/shared/types/profile';

const profile: AdminProfile = {
  user: {
    changePassword: false,
    email: 'hannahuser@tribehero.com',
    userName: 'Hannah Arendt',
  },
  userConfig: {
    language: 'en-US',
  },
};

export default profile;
