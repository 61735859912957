<template>
  <q-layout view="lHh lpR fFf">
    <AppLayoutDrawer v-model="isDrawerOpen" :mini="miniState" />
    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppLayoutDrawer from '@/components/app/AppLayoutDrawer/AppLayoutDrawer.vue';

export default defineComponent({
  name: 'AppLayout',

  components: { AppLayoutDrawer },

  data() {
    return {
      isDrawerOpen: true,
    };
  },

  computed: {
    miniState(): boolean {
      return (
        ['xs', 'sm'].includes(this.$q.screen.name) ||
        this.$route.meta.miniMode === true
      );
    },
  },
});
</script>
