const site = {
  name: 'Barcelona \uD83D\uDC83\uD83C\uDFFE',
  reference: '64cf2bc9-78d4-4da4-a2de-c7230cada187',
  city: 'Barcelona',
  country: 'Spain',
  timezone: {
    utcOffset: 'UTC+02:00',
    ianaTimezone: 'Europe/Madrid',
  },
};

export default site;
